// extracted by mini-css-extract-plugin
export var top_container = "Game-module--top_container--3cqPm";
export var no_word = "Game-module--no_word--2EQgi";
export var prev = "Game-module--prev--3p45-";
export var prev_mobile = "Game-module--prev_mobile--11uyE";
export var disabled = "Game-module--disabled--eqSrU";
export var hidden = "Game-module--hidden--1SZ48";
export var next = "Game-module--next--35p5U";
export var next_mobile = "Game-module--next_mobile--I35I1";
export var bottom_container = "Game-module--bottom_container--IcP6g";
export var acknowledgments_container = "Game-module--acknowledgments_container--2B80r";
export var word_container = "Game-module--word_container--2p1yn";
export var warning_message_container = "Game-module--warning_message_container--2soWE";
export var first_slide = "Game-module--first_slide--3smOK";