// extracted by mini-css-extract-plugin
export var letters_container = "LetterSelection-module--letters_container--N4LCD";
export var clicked_letters_container = "LetterSelection-module--clicked_letters_container--1e663";
export var clicked_letter = "LetterSelection-module--clicked_letter--1nwFi";
export var correct = "LetterSelection-module--correct--1sCml";
export var wrong = "LetterSelection-module--wrong--36zIm";
export var buttons_container = "LetterSelection-module--buttons_container--2cm_y";
export var selectable_letters_container = "LetterSelection-module--selectable_letters_container--1hJ1c";
export var selectable_letter = "LetterSelection-module--selectable_letter--1Tgq1";
export var disabled = "LetterSelection-module--disabled--EYChO";
export var filled = "LetterSelection-module--filled--1YEvL";
export var utility_buttons_container = "LetterSelection-module--utility_buttons_container--zDHMf";
export var backspace = "LetterSelection-module--backspace--bH7Ur";
export var submit = "LetterSelection-module--submit--l_OWy";